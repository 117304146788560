import { makeAutoObservable } from 'mobx'
import createAuth0Client from '@auth0/auth0-spa-js'
import { get } from 'lodash'
import { getInstance } from '../../utils'
import api from '../../api'

const isDevMode = process.env.NODE_ENV === 'development'

async function fetchConfigFromApi() {
  const response = await fetch('/config/auth')
  const config = await response.json()

  localStorage.setItem('authConfig', JSON.stringify(config))
  return config
}

class AuthStore {
  authClient = null
  token = null
  state = 'pending' // "pending", "done" or "error"
  role = ''
  user = {}
  isSuperAdmin = false
  permissions = []

  constructor() {
    makeAutoObservable(this)
  }

  *initialize() {
    try {
      const config = yield this.fetchConfig()

      this.authClient = yield createAuth0Client({
        client_id: config.client,
        domain: config.domain,
        redirect_uri: window.location.origin + '/admin/callback',
        cacheLocation:
          process.env.NODE_ENV === 'development' || window.Cypress
            ? 'localstorage'
            : 'memory',
      })

      this.user = yield this.authClient.getUser()
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      console.error(error)
    }
  }

  *fetchConfig() {
    let config

    if (isDevMode && localStorage.getItem('authConfig')) {
      const configString = localStorage.getItem('authConfig')

      config = JSON.parse(configString)
    } else {
      config = yield fetchConfigFromApi()
    }

    return config
  }

  *login() {
    yield this.authClient.loginWithRedirect()
  }

  *logout() {
    yield this.authClient.logout({
      federated: true,
      returnTo: window.location.origin,
    })
    this.user = {}
    const currentLanguage =
      localStorage.getItem('language') || localStorage.getItem('locale')

    localStorage.clear()

    if (currentLanguage) {
      localStorage.setItem('language', currentLanguage)
      localStorage.setItem('locale', currentLanguage)
    }
  }

  *getUser() {
    //yield this.authClient.getUser()
  }

  *callback() {
    yield this.authClient.handleRedirectCallback()
    yield this.setToken()
  }

  *setToken() {
    const claims = yield this.authClient.getIdTokenClaims()

    this.token = claims.__raw
  }

  *isAuthenticated() {
    const isAuthenticated = yield this.authClient.isAuthenticated()

    if (isAuthenticated) {
      yield this.setToken()
    }

    return isAuthenticated
  }

  *fetchRole() {
    const user = yield this.authClient?.getUser()
    if (user === undefined || window.Cypress) {
      this.role = 'admin'
      return
    }

    // fallback if auth0 role is missing to block access
    this.role = user['https://makaira.io/roles'][0]
      ? user['https://makaira.io/roles'][0]
      : 'missing'

    this.isSuperAdmin = user['https://makaira.io/full_access']
  }

  *fetchPermissions() {
    const user = yield this.authClient?.getUser()
    if (user === undefined) {
      this.permissions = []
      return
    }
    const roles = user['https://makaira.io/domains']

    const regex = /^https?:\/\/([^.]+)\.makaira\.io/
    const match = window.location.origin.match(regex)
    const domain = match && match.length > 0 ? match[1] : 'local' // if does not match -> must be makaira.vm
    const rolesBaseOnDomain = [
      ...get(roles, [domain, '*'], []),
      ...get(roles, [domain, getInstance()], []),
    ]
    try {
      const permissionsTranslated = yield api.common.permissionsPreview(
        rolesBaseOnDomain
      )
      this.permissions = permissionsTranslated.data
    } catch (error) {
      console.error(error)
    }
  }
}

export default new AuthStore()
