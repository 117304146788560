import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import classNames from 'classnames'

import URLInput from './URLInput'
import PreviewActions from './PreviewActions'

import UIStore from '../../../stores/UIStore'
import EditorStore from '../../../stores/PageEditor/EditorStore'
import ConfigurationStore from '../../../stores/ConfigurationStore'
import PageEditorPreviewStore from '../../../stores/PageEditor/PageEditorPreviewStore'

import debounce from '../../../utils/debounce'

import './preview.styl'
import NotWorking from './NotWorking'

function Preview({ isConfigurationPanelShown }) {
  const iframeRef = useRef(null)
  const storefrontResponseTimeout = useRef(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [storefrontVersion, setStorefrontVersion] = useState()
  const [storefrontTimedOut, setStorefrontTimedOut] = useState(false)
  const [fixedPreview, setFixedPreview] = useState(false)

  const {
    previewShown,
    selectedSize,
    reloadIframe,
    setReloadIframe,
    handleURLPreview,
  } = PageEditorPreviewStore

  const TARGET_DOMAIN =
    PageEditorPreviewStore.customPreviewTargetURL ||
    ConfigurationStore.configuration.previewUrl ||
    'http://localhost:5000'

  const pageToEditJS = toJS(EditorStore.pageToEdit)

  /**
   * Effect-Hook that is triggered when the changes to the page store were made OR
   * when the storefront version updates after loading and initial "handshake" with
   * storefront.
   * Update will be debounced by 1 second.
   */
  useEffect(() => {
    if (status !== 'READY') return

    debouncedTriggerPreviewUpdate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageToEditJS, storefrontVersion, EditorStore.contentLanguage])

  /**
   * Effect-Hook that register a listener for the Message-Interface.
   * Will take care of the "requestVersion"-Response and redirects
   * postMessages from the UI-App to the iframe.
   */
  useEffect(() => {
    window.addEventListener('message', handleMessageEvent)

    return () => {
      window.removeEventListener('message', handleMessageEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TARGET_DOMAIN])

  useEffect(() => {
    setReloadIframe(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EditorStore.contentLanguage])

  useEffect(() => {
    if (reloadIframe) {
      setIframeLoaded(false)
      const src = iframeRef.current.src
      iframeRef.current.src = src
      setReloadIframe(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadIframe])

  /**
   * Effect-Hook that is triggered when the IFrame itself is loaded.
   * When loaded we want to query the version from the storefront.
   * We also set a timeout for this request to know if we really talk
   * with a preview-capable storefront version.
   */
  useEffect(() => {
    if (!iframeLoaded) return
    const formattedTargetDomain =
      PageEditorPreviewStore.handleURLPreview(TARGET_DOMAIN)

    if (formattedTargetDomain === '') {
      console.log('iframe not loaded, invalid url', formattedTargetDomain)
      return
    }

    console.log(
      'iframe loaded, storefront has not responded yet',
      formattedTargetDomain
    )

    // We wait for 5 seconds for a response from the storefront,
    storefrontResponseTimeout.current = setTimeout(() => {
      setStorefrontTimedOut(true)
    }, 5000)

    setTimeout(() => {
      // We ask the storefront to return its version.
      iframeRef.current.contentWindow.postMessage(
        {
          source: 'makaira-bridge',
          action: 'reportVersion',
        },
        formattedTargetDomain // the target origin - the iframe again
      )
    }, 200)

    return () => {
      clearTimeout(storefrontResponseTimeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeLoaded, TARGET_DOMAIN])

  useEffect(() => {
    setIframeLoaded(false)
    setStorefrontVersion(null)
  }, [TARGET_DOMAIN])

  const getConfigPanelHeight = () => {
    if (!isConfigurationPanelShown) {
      return 0
    }
    return document.querySelector('.configuration-panel').offsetHeight
  }

  const checkShowFixedPreview = () => {
    if (!fixedPreview && window.scrollY > 77 + getConfigPanelHeight()) {
      setFixedPreview(true)
    } else {
      setFixedPreview(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkShowFixedPreview)
    EditorStore.previewPopup?.postMessage({
      source: 'makaira-bridge',
      action: 'preview-status',
      config: 'continue',
    })
    return () => {
      window.removeEventListener('scroll', checkShowFixedPreview)
      EditorStore.previewPopup?.postMessage({
        source: 'makaira-bridge',
        action: 'preview-status',
        config: 'break',
      })
      // eslint-disable-next-line
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMessageEvent = (event) => {
    const { source, action, config } = event.data
    if (source !== 'makaira-bridge') return

    if (action === 'responseVersion') {
      handleVersionResponse(event.data.version)
      return
    }

    if (action === 'preview-status') {
      EditorStore.setPreviewPopupStatus(config)
    }

    // opener will trigger update when receive message from popup
    if (action === 're-update') {
      triggerPreviewUpdate()
    }

    if (action === 'update') {
      // We post the message to the iframe's window
      iframeRef.current.contentWindow.postMessage(
        {
          source: 'makaira-bridge',
          action: 'update',
          payload: { ...config },
        },
        handleURLPreview(TARGET_DOMAIN) // the target origin - the iframe again
      )

      // We also post the message to the popup if it exists
      EditorStore.previewPopup?.postMessage(
        {
          source: 'makaira-bridge',
          action: 'update',
          config: { ...config },
        },
        window.location.origin
      )
    }

    if (action === 'selected-element') {
      iframeRef.current.contentWindow.postMessage(
        {
          source: 'makaira-bridge',
          action: 'selected-element',
          payload: config,
        },
        handleURLPreview(TARGET_DOMAIN)
      )
      EditorStore.previewPopup?.postMessage(
        {
          source: 'makaira-bridge',
          action: 'selected-element',
          config,
        },
        window.location.origin
      )
    }
  }

  const handleVersionResponse = (version) => {
    if (!version) {
      return
    }

    console.log('storefront responded and is preview capable', version)

    // The storefront answers with a version number.
    // At this point we know, that the preview feature is fully available
    clearTimeout(storefrontResponseTimeout.current)
    setStorefrontTimedOut(false)
    setStorefrontVersion(version)
  }

  const getPreviewStatus = () => {
    if (!iframeLoaded) return 'LOADING'
    if (iframeLoaded && !storefrontTimedOut && storefrontVersion) return 'READY'
    if (iframeLoaded && !storefrontTimedOut) return 'WAITING_FOR_STOREFRONT'
    if (iframeLoaded && storefrontTimedOut) return 'STOREFRONT_NOT_COMPATIBLE'
  }

  const triggerPreviewUpdate = () => {
    // popup will send a message to opener to trigger update preview data
    if (UIStore.isStorefrontPreview) {
      window.opener?.postMessage(
        {
          source: 'makaira-bridge',
          action: 're-update',
        },
        window.location.origin
      )
    }

    PageEditorPreviewStore.updatePreview()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTriggerPreviewUpdate = useCallback(
    debounce(triggerPreviewUpdate, 500),
    []
  )

  const status = getPreviewStatus()

  const handleIframeLoaded = () => {
    setIframeLoaded(true)
  }

  if (UIStore.isStorefrontPreview) {
    return (
      <>
        {status === 'STOREFRONT_NOT_COMPATIBLE' &&
          EditorStore.previewPopupStatus !== 'break' && (
            <NotWorking isStorefrontPreview type="compatible" />
          )}
        {EditorStore.previewPopupStatus === 'break' && (
          <NotWorking isStorefrontPreview type="break" />
        )}
        <iframe
          ref={iframeRef}
          src={PageEditorPreviewStore.handleURLPreview(TARGET_DOMAIN)}
          className={classNames(
            'editor-preview__iframe editor-preview__iframe--full',
            {
              'editor-preview__iframe--not-working': status !== 'READY',
            }
          )}
          onLoad={() => setIframeLoaded(true)}
        />
      </>
    )
  }

  return (
    <div className="editor-preview">
      <div className="editor-preview__overlay-wrapper">
        {ReactDOM.createPortal(
          <div
            className={classNames('editor-preview__overlay', {
              'editor-preview__overlay--hide':
                isConfigurationPanelShown && !fixedPreview,
              'editor-preview__overlay--fixed': fixedPreview,
              'editor-preview__overlay--preview-hidden': !previewShown,
            })}
          >
            <PreviewActions />

            <div
              className={classNames('editor-preview__monitor', {
                'editor-preview__monitor--hidden': !previewShown,
              })}
              style={{
                width: selectedSize,
              }}
            >
              <URLInput
                activeTargetURL={TARGET_DOMAIN}
                onClickReload={() => setReloadIframe(true)}
              />
              {status === 'STOREFRONT_NOT_COMPATIBLE' && (
                <NotWorking type="compatible" />
              )}
              {status !== 'READY' && status !== 'STOREFRONT_NOT_COMPATIBLE' && (
                <div className="editor-preview__loading" />
              )}
              <iframe
                key={TARGET_DOMAIN}
                ref={iframeRef}
                src={PageEditorPreviewStore.handleURLPreview(TARGET_DOMAIN)}
                className={classNames('editor-preview__iframe', {
                  'editor-preview__iframe--not-working': status !== 'READY',
                })}
                onLoad={handleIframeLoaded}
              />
            </div>
          </div>,
          document.body
        )}
      </div>
    </div>
  )
}

export default observer(Preview)
